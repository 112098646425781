import './App.css';
import { Grid } from '@mui/material'

function App() {
  return (
   <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ height: '100vh'}}>
    <Grid item xs={3}>
        COMING SOON!!!
    </Grid>
   </Grid>
  );
}

export default App;
